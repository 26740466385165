import React from 'react'
import Navmobile from './Navmobile'
import Mobilefooter from './Mobilefooter'
import Nav from '../Nav';
import SectionFive from '../SectionFive';

function MobileTv() {
  return (
    <div>
      <div className="desktopnavigator">
        <Nav />
      </div>
      <div className="mobilesnavigator">
        <Navmobile />
      </div>
      <div className="mobiletvholder">
        <div className="mobiletvstack">
          <div className="mobiletvcard">
            <a href="" target="_blank" className="mtvlink">
              <div className="mtvimg">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/IBNxHWlq--s?si=oiswlIqvYrhZwHgc"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="mtvtitle">Wase Rock TV Live Stream</div>
            </a>
          </div>
          <div className="mobiletvcard">
            <a href="" target="_blank" className="mtvlink">
              <div className="mtvimg">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/K0LxK3QUlHM?si=kSJfeFNkE8KPwlEF"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="mtvtitle">
                The Plateau State Council of Chiefs and Emirs Sends Strong
                Warnings
              </div>
            </a>
          </div>

          <div className="mobiletvcard">
            <a href="" target="_blank" className="mtvlink">
              <div className="mtvimg">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/4ny8rtH1fKQ?si=dYhVTeX5xprrwjX2"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="mtvtitle">
                Chairman, Wase LGC, Shows Commitment to Peace & Unity at
                Operation Safe Haven Football Tournament!
              </div>
            </a>
          </div>

          <div className="mobiletvcard">
            <a
              href="https://youtu.be/LRPFH6RSURo?si=z-EagSZYnmDi6LCi"
              target="_blank"
              className="mtvlink"
            >
              <div className="mtvimg">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/LRPFH6RSURo?si=9pTzg84B988pYsUq"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="mtvtitle">
                HIS EXCELLENCY GOV. MUTFWANG PRESENTS CERTIFICATE TO HON. HAMISU
                ANANI, WASE LGA CHAIR!
              </div>
            </a>
          </div>

          <div className="mobiletvcard">
            <a
              href="https://youtu.be/_WMBvRhfyqY?si=N-NJpYaM4p7eSSOD"
              target="_blank"
              className="mtvlink"
            >
              <div className="mtvimg">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/_WMBvRhfyqY?si=KF5NEDybdAYRFT_z"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="mtvtitle">
                CELEBRATION ERUPTS AS HON. HAMISU ANANI WINS CHAIRMANSHIP OF
                WASE LGA
              </div>
            </a>
          </div>

          <div className="mobiletvcard">
            <a
              href="https://youtu.be/Y-61wrb9OAE?si=tllbUqm70sTS9qVt"
              target="_blank"
              className="mtvlink"
            >
              <div className="mtvimg">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/Y-61wrb9OAE?si=tllbUqm70sTS9qVt"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="mtvtitle">
                THE MAN HAMISU ANANI - MAKING A DIFFERENCE, ONE STEP AT A TIME
              </div>
            </a>
          </div>

          <div className="mobiletvcard">
            <a
              href="https://youtu.be/p08RhbjO3pg?si=x2596vHHP8qpqQst"
              target="_blank"
              className="mtvlink"
            >
              <div className="mtvimg">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/Zw31dEkXu48?si=TYRtbgeAlui2nNR6"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="mtvtitle">
                PDP LG Chairmanship Candidate, Wase Local Government Speaks at
                Campaign Flag off Ceremony
              </div>
            </a>
          </div>

          <div className="mobiletvcard">
            <a
              href="https://youtu.be/fOqiXPTBG2Q?si=Oxyl2n3tUJSUtKGM"
              target="_blank"
              className="mtvlink"
            >
              <div className="mtvimg">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/fOqiXPTBG2Q?si=2_bPQIQ1g9oDq0CV"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="mtvtitle">
                Gov. Mutfwang Flags off Campaigns for LGA Elections in Plateau
                South
              </div>
            </a>
          </div>

          <div className="mobiletvcard">
            <a
              href="https://youtu.be/uUpKXyJwexw?si=92Oyv8CZZ2iDqp9g"
              target="_blank"
              className="mtvlink"
            >
              <div className="mtvimg">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/p08RhbjO3pg?si=TGwH-8WFox0cz93K"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="mtvtitle">
                Chairmanship Candidate for WASE LGA receives donations for
                Campaigns from Elder Statesman
              </div>
            </a>
          </div>

          <div className="mobiletvcard">
            <a
              href="https://youtu.be/uUpKXyJwexw?si=92Oyv8CZZ2iDqp9g"
              target="_blank"
              className="mtvlink"
            >
              <div className="mtvimg">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/uUpKXyJwexw?si=VnOjUFEA_Uq6wt1E"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="mtvtitle">
                PLATEAU STATE PDP FLAGS OFF CAMPAIGN RALLY
              </div>
            </a>
          </div>
          <div className="mobiletvcard">
            <a
              href="https://youtu.be/RBzU96247ak?si=oK1Bt54U_tuSuL-4"
              target="_blank"
              className="mtvlink"
            >
              <div className="mtvimg">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/RBzU96247ak?si=J1tk6p3W4B0bkiNi"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="mtvtitle">
                Plateau State Governor Convenes Crucial Meeting with PDP
                Stakeholders
              </div>
            </a>
          </div>
          <div className="mobiletvcard">
            <a
              href="https://youtu.be/7GTfeZV5w-I?si=kfu8hVbNlySOZOb5"
              target="_blank"
              className="mtvlink"
            >
              <div className="mtvimg">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/7GTfeZV5w-I?si=dPQJKAAacYyXTu3j"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="mtvtitle">
                Plateau State Government Appeals to Youths to Shun Protest
              </div>
            </a>
          </div>
          <div className="mobiletvcard">
            <a
              href="https://youtu.be/txq6bXRzefE?si=hXFsa93CGPoO_YfS"
              target="_blank"
              className="mtvlink"
            >
              <div className="mtvimg">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/txq6bXRzefE?si=Ndu4q8Cg-sEsvsD7"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="mtvtitle">
                Tripartite Union of Nigerian Workers in Wase LGA, Purchase
                Nomination Form for Incumbent Chairman
              </div>
            </a>
          </div>
          <div className="mobiletvcard">
            <a
              href="https://youtu.be/649U72wNGTc?si=67gB9W-6myOcxhV_"
              target="_blank"
              className="mtvlink"
            >
              <div className="mtvimg">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/649U72wNGTc?si=HowY8mKJ9PtjIewI"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="mtvtitle">
                Hon. Hamisu Mohammed Anani, Chairman of Wase LGA & Algon
                Chairman, Plateau State, gives a heartfelt vote of thanks at the
                commissioning of the newly renovated assembly hall of Government
                Science College, Wase, courtesy of the Nigerian Army.
              </div>
            </a>
          </div>
          <div className="mobiletvcard">
            <a
              href="https://youtu.be/jE4xkgxVDaM?si=Vkl1h4al_KiqOxgR"
              target="_blank"
              className="mtvlink"
            >
              <div className="mtvimg">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/jE4xkgxVDaM?si=E9P1UjKdmgtPNCL6"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="mtvtitle">
                GOVERNOR MUTFWANG URGES FORGIVENESS AND UNITY FOR A STRONGER
                PLATEAU DURING THANKSGIVING
              </div>
            </a>
          </div>
          <div className="mobiletvcard">
            <a
              href="https://youtu.be/ogD0IbinkMI?si=5E517V2b72bENsBX"
              target="_blank"
              className="mtvlink"
            >
              <div className="mtvimg">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/ogD0IbinkMI?si=KegFh-phLVX29_cK"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="mtvtitle">
                PDP GOVERNORS' FORUM COMMISERATES WITH PLATEAU, DONATES N100
                MILLION TO VICTIMS OF VIOLENT ATTACKS
              </div>
            </a>
          </div>
          <div className="mobiletvcard">
            <a
              href="https://youtu.be/Pp92qe89M2o?si=1QZ5cwd4ob-UojEa"
              target="_blank"
              className="mtvlink"
            >
              <div className="mtvimg">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/Pp92qe89M2o?si=LnYWm19hwpuaOBVs"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="mtvtitle">
                Commissioner for Local Government & Chieftaincy Affairs inspects
                projects of the 17 LGC TIC Chairmen
              </div>
            </a>
          </div>
          <div className="mobiletvcard">
            <a
              href="https://youtu.be/Fu2oAJSRSzE?si=m23Ykr8lHuhiX5C2"
              target="_blank"
              className="mtvlink"
            >
              <div className="mtvimg">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/Fu2oAJSRSzE?si=cPky436xYhnN7frk"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="mtvtitle">
                GOVERNOR MUTFWANG VISITS MANGU LG TO COMMISERATE WITH THE
                VICTIMS OF THE RECENT ATTACK IN MANGU
              </div>
            </a>
          </div>
          <div className="mobiletvcard">
            <a
              href="https://youtu.be/ZQhzYqUWMX0?si=7Hxa5-pcqvXSA0Kd"
              target="_blank"
              className="mtvlink"
            >
              <div className="mtvimg">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/ZQhzYqUWMX0?si=JAKkhpWTSr2F40be"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="mtvtitle">
                Rousing Homecoming of Gov. Caleb Mutfwang_the People's Governor,
                after Victory at Supreme Court
              </div>
            </a>
          </div>
          <div className="mobiletvcard">
            <a
              href="https://youtu.be/iul8beIsk5Q?si=ZsoLya8oTDY9PZxh"
              target="_blank"
              className="mtvlink"
            >
              <div className="mtvimg">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/kljuSv8xf9A?si=2AOwfLhtfqgOZa49"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="mtvtitle">
                Jubilation in Plateau State over Gov. Caleb Mutfwang's
                victory!!!
              </div>
            </a>
          </div>
          <div className="mobiletvcard">
            <a
              href="https://youtu.be/B-DzSIUiitU?si=i2k__YC5m44S9Co0"
              target="_blank"
              className="mtvlink"
            >
              <div className="mtvimg">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/B-DzSIUiitU?si=9uyPL2-OhpZJq2Ag"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="mtvtitle">
                Celebrations across Plateau State over Governor Mutfwang's
                victory at the Supreme Court today
              </div>
            </a>
          </div>
          <div className="mobiletvcard">
            <a
              href="https://www.youtube.com/channel/UCHVdxwG2WGh15TQLLrsobOA"
              target="_blank"
              className="mtvlink"
            >
              <div className="mtvimg">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/iul8beIsk5Q?si=53AEUvYXBe9TWqeo"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="mtvtitle">
                16 Million Naira Tuition Fee Scholarship for indigenes of Wase
                into Tertiary Institution
              </div>
            </a>
          </div>
          <div className="mobiletvcard">
            <a
              href="https://youtu.be/lZ0cZ1Cd5Y0?si=U-X4Tha8Bh1oYDE7"
              target="_blank"
              className="mtvlink"
            >
              <div className="mtvimg">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/lZ0cZ1Cd5Y0?si=KQKQlQCk4l3aYZkL"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="mtvtitle">
                Muslim Ummah in Plateau State, gather to pray for Justice and
                Victory for Governor Caleb Mutfwang of Plateau State.
              </div>
            </a>
          </div>
          <div className="mobiletvcard">
            <a
              href="https://www.youtube.com/channel/UCHVdxwG2WGh15TQLLrsobOA"
              target="_blank"
              className="mtvlink"
            >
              <div className="mtvimg">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/byrALj63RRA?si=s-rP9D-d2TspJMny"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="mtvtitle">
                COTLA COMMITMENTS ONDO ADAMAWA CROSS RIVER STATES RESPECTIVELY
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="destopfooter">
        <SectionFive />
      </div>
      <div className="mobilesfooter">
        <Mobilefooter />
      </div>
    </div>
  );
}

export default MobileTv